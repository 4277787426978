export interface ActivityLogSearchCriteria {
    processId?: string | null,
    message?: string | null,
    loggedBy?: string | null,
    logDateFrom?: Date | null,
    logDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_ACTIVITY_LOG_SEARCH_CRITERIA : ActivityLogSearchCriteria = {
    
    processId: null,
    message: null,
    loggedBy: null,
    logDateFrom: null,
    logDateTo: null,

}