
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { ActivityLogSearchPanel } from "./ActivityLogSearchPanel";
import ActivityLogTablePanel from "./ActivityLogTablePanel";

export const ActivityLogMasterView: React.FC = () => {
    const [activityLogState] = useActivityLogMaintenanceTracked();
    const { isShowCriteriaPanel } = activityLogState;

    return <>
        <div className={`main-comp-wrapper${(isShowCriteriaPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                leftSectionWidth={isShowCriteriaPanel ? '22%' : '0%'}
                rightSectionWidth={isShowCriteriaPanel ? '78%' : '100%'}
                draggable={false}
                leftChildren={<ActivityLogSearchPanel />}
                rightChildren={<ActivityLogTablePanel />} />
        </div>
    </>
}