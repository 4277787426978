
import { ActivityLogEntity } from "domain/entity/ActivityLog/ActivityLogEntity";
import { ActivityLogSearchCriteria, EMPTY_ACTIVITY_LOG_SEARCH_CRITERIA } from "presentation/constant/ActivityLog/ActivityLogSearchCriteria";

export interface ActivityLogMaintenanceModel {
    isLoading: boolean,
    tableData: ActivityLogEntity[],
    isShowCriteriaPanel: boolean,
    searchCriteria: ActivityLogSearchCriteria,
}

export const EMPTY_MASTER_ACTIVITY_LOGANY_MODEL: ActivityLogMaintenanceModel = {
    isLoading: false,
    tableData: [],
    isShowCriteriaPanel: false,
    searchCriteria: EMPTY_ACTIVITY_LOG_SEARCH_CRITERIA,
}