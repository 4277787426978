import { ActivityLogRepoImpl } from "domain/repository/ActivityLog/ActivityLogRepoImpl";
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { ActivityLogMaintenanceVM } from "presentation/viewModel/ActivityLog/ActivityLogMaintenanceVM";
import { useMemo } from "react";

export const useActivityLogMaintenanceVM = () => {
    const [, setActivityLogMaintenanceState] = useActivityLogMaintenanceTracked();
    const activityLogMainVM = useMemo(() =>
        ActivityLogMaintenanceVM({
            dispatch: [setActivityLogMaintenanceState],
            repo: ActivityLogRepoImpl(),
        }), [setActivityLogMaintenanceState])

    return activityLogMainVM
}

