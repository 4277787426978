import { EMPTY_MASTER_ACTIVITY_LOGANY_MODEL } from "presentation/model/ActivityLog/ActivityLogMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: ActivityLogMaintenanceProvider,
    useTracked: useActivityLogMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_ACTIVITY_LOGANY_MODEL), {concurrentMode: true});
export { ActivityLogMaintenanceProvider, useActivityLogMaintenanceTracked };

