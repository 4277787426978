export const activityLogMaintenanceRequiredFieldList: string[] = [
    'activityLog', 
];

export const ActivityLogMaintenanceConstant = {    
    TITLE: "Activity Log",
    LOG_DATE_TIME: "Log Date Time",
    LOG_DATE_TIME_FROM: "Log Date/Time From",
    LOG_DATE_TIME_TO: "Log Date/Time To",
    LOGGED_BY: "Logged By",
    PROCESS_ID: "Process ID",
    OPERATING_COMPANY: "Operating Company",
    OPERATING_UNIT: "Operating Unit",
    MESSAGE: "Message",
}
