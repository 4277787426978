import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ActivityLogMaintenanceProvider } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ActivityLogMasterView } from "presentation/view/section/ActivityLog/ActivityLogMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ActivityLogMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.ACTIVITY_LOG_MAINTENANCE}>
        <MessageBarWrapper>
            <ActivityLogMaintenanceProvider>
                <GridStyles/>
                <ActivityLogMasterView/>
            </ActivityLogMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ActivityLogMaintenanceCont;