import { INITIAL_ACTIVITY_LOG_COL_DEF } from "presentation/constant/ActivityLog/ActivityLogMaintenanceColumnDefinition";
import { ActivityLogMaintenanceConstant } from "presentation/constant/ActivityLog/ActivityLogMaintenanceConstant";
import { useActivityLogMaintenanceVM } from "presentation/hook/ActivityLog/useActivityLogMaintenanceVM";
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";

const ActivityLogTablePanel: React.FC = () => {
    const [activityLogState] = useActivityLogMaintenanceTracked();
    const gridRef: any = useRef(null);
    const { tableData } = activityLogState;
    const activityLogVM = useActivityLogMaintenanceVM();

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onSearchButton',
            icon: 'Icon-search',
            title: 'Search'
        })
        return btns;
    }, [])

    const handleSearchClick = useCallback(() => {
        activityLogVM.onToggleCriteriaPanel();
    }, [activityLogVM]);

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='activity-log-table'
                isNewColumnSetting={true}
                headerLabel={ActivityLogMaintenanceConstant.TITLE}
                columns={INITIAL_ACTIVITY_LOG_COL_DEF}
                data={tableData ?? []}
                headerActionButtons={tbBtns}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
                onSearchButton={handleSearchClick}
            />
        );
    }, [tableData, handleSearchClick, tbBtns])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(ActivityLogTablePanel);
