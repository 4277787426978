import { ActivityLogRepository } from "domain/repository/ActivityLog/ActivityLogRepo";
import { ActivityLogSearchCriteria, EMPTY_ACTIVITY_LOG_SEARCH_CRITERIA } from "presentation/constant/ActivityLog/ActivityLogSearchCriteria";
import { ActivityLogMaintenanceModel } from "presentation/model/ActivityLog/ActivityLogMaintenanceModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface ActivityLogMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ActivityLogMaintenanceModel>> | ((value: SetStateAction<ActivityLogMaintenanceModel>) => void),
    ],
    repo: ActivityLogRepository,
}

export const ActivityLogMaintenanceVM = ({ dispatch, repo }: ActivityLogMaintenanceVMProps) => {
    const [activityLogMainDispatch] = dispatch;

    const onSearch = async (searchCriteria: ActivityLogSearchCriteria) => {

        activityLogMainDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: { ...searchCriteria }
            }
        })

        await repo.getEntities({
            ...searchCriteria,
        }).then((data) => {
            activityLogMainDispatch(prevState => {
                return {
                    ...prevState,
                    tableData: data
                }
            })
        })
    }

    const onRemoveAllSearchCriteria = () => {
        activityLogMainDispatch(prevState => {
            return {
                ...prevState, searchCriteria: { ...EMPTY_ACTIVITY_LOG_SEARCH_CRITERIA }
            }
        })
    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val = e.target.value;
        if (fieldName !== 'message') {
            val = e.target.value.replace(/\s+/g, '');
        }

        activityLogMainDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };
    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        activityLogMainDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onToggleCriteriaPanel = async () => {
        activityLogMainDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }


    return {
        onDateRangeChange: onDateRangeChange,
        onSearch: onSearch,
        onToggleCriteriaPanel: onToggleCriteriaPanel,
        onInputTextChange: onInputTextChange,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
    }
}