import { ActivityLogEntity } from "domain/entity/ActivityLog/ActivityLogEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ActivityLogRepository } from "./ActivityLogRepo";
import { ActivityLogSearchCriteria } from "presentation/constant/ActivityLog/ActivityLogSearchCriteria";

/**
 * For template
 * @returns 
 */
export const ActivityLogRepoImpl = (): ActivityLogRepository => {
    const url = '/v1/activityLogSearch';

    const getEntities = (request: ActivityLogSearchCriteria) : Promise<ActivityLogEntity[]> => {
        return axiosPostData(referenceAxiosInstance, url, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getEntities: getEntities
    }
}