import { ActivityLogMaintenanceConstant } from "presentation/constant/ActivityLog/ActivityLogMaintenanceConstant";
import { ActivityLogSearchCriteria } from "presentation/constant/ActivityLog/ActivityLogSearchCriteria";
import { useActivityLogMaintenanceVM } from "presentation/hook/ActivityLog/useActivityLogMaintenanceVM";
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useCallback, useMemo, useState } from "react";
import { HPHButton, IconButton, InputField, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ActivityLogSearchPanel = () => {
    const [activityLogState] = useActivityLogMaintenanceTracked();
    const activityLogVM = useActivityLogMaintenanceVM();
    const {searchCriteria} = activityLogState;
    const SCREEN_CONSTANT = ActivityLogMaintenanceConstant;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        activityLogVM.onRemoveAllSearchCriteria();
    }, [activityLogVM]);
    
    const searchButtonClicked = useCallback((criteria:ActivityLogSearchCriteria) => {
        setIsLoading(true);
        activityLogVM.onSearch(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [activityLogVM]);

    const memoProcessId = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <InputField
                width='150px'
                maxLength={999}
                label={SCREEN_CONSTANT.PROCESS_ID}
                type="text"
                value={searchCriteria.processId || ''}
                onChange={(e) => activityLogVM.onInputTextChange(e, 'processId')}/>
        </div>
    , [searchCriteria?.processId, SCREEN_CONSTANT.PROCESS_ID, activityLogVM])

    const memoLoggedBy = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <InputField
                width='150px'
                maxLength={999}
                label={SCREEN_CONSTANT.LOGGED_BY}
                type="text"
                value={searchCriteria.loggedBy || ''}
                onChange={(e) => activityLogVM.onInputTextChange(e, 'loggedBy')}/>
        </div>
    , [searchCriteria?.loggedBy, SCREEN_CONSTANT.LOGGED_BY, activityLogVM])

    const memoFromToDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <DateRangePickerComponent
                label={SCREEN_CONSTANT.LOG_DATE_TIME}
                width='330px'
                fieldNames={{startField:"logDateFrom", endField:"logDateTo"}}
                dateRange={{startDate:searchCriteria.logDateFrom, endDate:searchCriteria.logDateTo}}
                onDatesChange={activityLogVM.onDateRangeChange}
            />                
        </div>
    , [SCREEN_CONSTANT.LOG_DATE_TIME, activityLogVM.onDateRangeChange, searchCriteria.logDateFrom, searchCriteria.logDateTo])

    const memoMessage = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <InputField
                width='330px'
                maxLength={999}
                label={SCREEN_CONSTANT.MESSAGE}
                type="text"
                value={searchCriteria.message || ''}
                onChange={(e) => activityLogVM.onInputTextChange(e, 'message')}/>
        </div>
    , [SCREEN_CONSTANT.MESSAGE, searchCriteria?.message, activityLogVM])


    return <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={activityLogVM.onToggleCriteriaPanel} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <CriteriaItemContainer>
                            {memoProcessId}
                            {memoLoggedBy}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoFromToDate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoMessage}
                        </CriteriaItemContainer>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(searchCriteria)} />
            </SidebarActionBar>
        </div>
}
